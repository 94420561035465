@use "../../../../styles/mixins";
@use "../../../../styles/variables.module" as *;

.block {
  width: 100%;
  position: relative;
  vertical-align: top;
  pointer-events: none;
  overflow: hidden;
  min-height: 200px;
  padding: 2% $terrace-outer-margin 2%;
  margin: 0;

  &:last-of-type {
    padding-bottom: 4%;
  }

  &[data-block-type="map"], &[data-block-type="statement"] {
    padding: 0;
  }

  &AboveTheFold {
    pointer-events: auto;
  }
}
