.container {
  > button {
    border: 1px solid white;
    border-radius: 5px;
    padding: .5rem;
  }
  div button {
    margin-left: 1rem;
    span {
      height: initial;
      width: initial;
    }
  }
  .fakeInput {
    background: rgba(255, 255, 255, .15);
    margin: 0 auto 15px auto;
    text-align: center;
    width: max-content;
    border-radius: 5px;
    display: block;
    padding: .25rem .5rem;
    font-size: 1.3rem;
    label {
      margin-right: 15px;
    }


    input {
      background: none;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}