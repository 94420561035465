@use "../../../../styles/variables.module" as *;

.cardListContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5vh;
  overflow-y: auto;
  height: calc(100vh - 2rem); // Adjust the height as needed
  padding: 0 90px 90px 90px;

  @media (max-width: $break-point-lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $break-point-sm) {
    grid-template-columns: 1fr;
    padding: 0 0 0 10%;
  }
}